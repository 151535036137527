import { graphql, useStaticQuery } from "gatsby"
import moment from "moment"
import React from "react"
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"

const BabyMenuFirstWeek = () => {
  // fetch all menus frontmatter
  const { allFile } = useStaticQuery(graphql`
    query BabyFirstWeekMenyQuery {
      allFile(filter: { sourceInstanceName: { eq: "baby-menus" } }) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              dateofmonth
              dayofweek
              dessert {
                allergens
                ingredients
                title
              }
              maincourse {
                allergens
                ingredients
                title
              }
              soup {
                allergens
                ingredients
                title
              }
            }
          }
        }
      }
    }
  `)

  // get all nodes from the markdown
  const menuItems = allFile.nodes
    .sort((a, b) => {
      const d1 = a.childMarkdownRemark.frontmatter.dateofmonth.split(".")
      const d2 = b.childMarkdownRemark.frontmatter.dateofmonth.split(".")

      let firstDate = moment(`${d1[2]}-${d1[1]}-${d1[0]}`)
      let secondDate = moment(`${d2[2]}-${d2[1]}-${d2[0]}`)

      return firstDate.diff(secondDate)
    })
    .slice(0, 6)

  return (
    <Layout>
      <div className="print-menu">
        {menuItems.map(({ childMarkdownRemark }, idx) => {
          const soup = childMarkdownRemark.frontmatter.soup
          const mainCourse = childMarkdownRemark.frontmatter.maincourse
          const dessert = childMarkdownRemark.frontmatter.dessert
          const menuDate = childMarkdownRemark.frontmatter.dateofmonth
          const day = childMarkdownRemark.frontmatter.dayofweek

          return (
            <div key={idx} className="print-menu-row">
              <h4 className="print-menu-day-title">
                {day} - {menuDate}
              </h4>
              <div className="print-menu-daily-menu-grid">
                <div className="print-menu-item">
                  <h5 className="print-menu-item-title">{soup.title}</h5>
                  <ReactMarkdown source={soup.ingredients} />
                </div>
                <div className="print-menu-item">
                  <h5 className="print-menu-item-title">{mainCourse.title}</h5>
                  <ReactMarkdown source={mainCourse.ingredients} />
                </div>
                <div className="print-menu-item">
                  <h5 className="print-menu-item-title">{dessert.title}</h5>
                  <ReactMarkdown source={dessert.ingredients} />
                </div>
              </div>
            </div>
          )
        })}

        <p style={{ marginTop: `30px` }}>* - алергени</p>
        <br />
        <p style={{ margin: 0, padding: 0 }}>
          Менюто е изготвено на база “Сборник рецепти и ръководство за
          здравословното хранене на деца до 3-годишна възраст”, съобразено е с
          Наредба # 2 на МЗ от 07.03.2013г. и е съгласувано с медицинско лице.
        </p>
      </div>
    </Layout>
  )
}

export default BabyMenuFirstWeek
